import React from 'react';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import App from './App';
import Awards from './awards/Awards';
import Home from './home/Home';
import About from './about/About';
import Story from './about/Story';
import Concept from './about/Concept';
import Reviews from './about/Reviews';
import Team from './team/Team';
import Services from './services/Services';
import Appointment from './services/Appointment';
import Goodbye from './services/Goodbye';
import Medication from './services/Medication';
import Register from './services/Register';
import Vip from './services/Vip';
import Visits from './services/Visits';
import Prices from './services/Prices';
import NotFound from './notfound/NotFound';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import Practice from './practice/Practice';
import Contact from './contact/Contact';
import TeamListComponent from './team/TeamListComponent';
import WorkWithUs from './team/WorkWithUs';
import Privacy from './policy/Privacy';
import Environmental from './policy/Environmental';
import Sustainability from './policy/Sustainability';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />}>
          <Route index element={<Navigate replace to="story" />} />
          <Route path="story" element={<Story />} />
          <Route path="concept" element={<Concept />} />
          <Route path="reviews" element={<Reviews />} />
        </Route>
        <Route path="awards" element={<Awards />} />
        <Route path="team" element={<Team />}>
          <Route index element={<Navigate replace to="everybody" />} />
          <Route path=":category" element={<TeamListComponent />} />
          <Route path="workwithus" element={<WorkWithUs />} />
        </Route>
        <Route path="services" element={<Services />}>
          <Route index element={<Navigate replace to="register" />} />
          <Route path="appointment" element={<Appointment />} />
          <Route path="goodbye" element={<Goodbye />} />
          <Route path="medication" element={<Medication />} />
          <Route path="register" element={<Register />} />
          <Route path="vip" element={<Vip />} />
          <Route path="visits" element={<Visits />} />
          <Route path="prices" element={<Prices />} />
        </Route>
        <Route path="practice" element={<Practice />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="environmental" element={<Environmental />} />
        <Route path="sustainability" element={<Sustainability />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
